import card1 from './organisms/SectionServices/imgs/img1.svg'
import card2 from './organisms/SectionServices/imgs/img2.svg'
import card3 from './organisms/SectionServices/imgs/img3.svg'
import card4 from './organisms/SectionServices/imgs/img4.svg'
//import card5 from './organisms/SectionServices/imgs/5n.svg'
import card5 from './organisms/SectionServices/imgs/img6.svg'


import case1 from './organisms/SectionCases/imgs/1_1920.png'
import case2 from './organisms/SectionCases/imgs/2_1920.png'
import case3 from './organisms/SectionCases/imgs/3_1920.png'
import case4 from './organisms/SectionCases/imgs/4_1920.png'
import case5 from './organisms/SectionCases/imgs/5_1920.png'
import case6 from './organisms/SectionCases/imgs/6_1920.png'
import case1_1280 from './organisms/SectionCases/imgs/1_1280.png'
import case2_1280 from './organisms/SectionCases/imgs/2_1280.png'
import case3_1280 from './organisms/SectionCases/imgs/3_1280.png'
import case4_1280 from './organisms/SectionCases/imgs/4_1280.png'
import case5_1280 from './organisms/SectionCases/imgs/5_1280.png'
import case6_1280 from './organisms/SectionCases/imgs/6_1280.png'
import case1_1024 from './organisms/SectionCases/imgs/1_1024.png'
import case2_1024 from './organisms/SectionCases/imgs/2_1024.png'
import case3_1024 from './organisms/SectionCases/imgs/3_1024.png'
import case4_1024 from './organisms/SectionCases/imgs/4_1024.png'
import case5_1024 from './organisms/SectionCases/imgs/5_1024.png'
import case6_1024 from './organisms/SectionCases/imgs/6_1024.png'
import case1_768 from './organisms/SectionCases/imgs/1_768.png'
import case2_768 from './organisms/SectionCases/imgs/2_768.png'
import case3_768 from './organisms/SectionCases/imgs/3_768.png'
import case4_768 from './organisms/SectionCases/imgs/4_768.png'
import case5_768 from './organisms/SectionCases/imgs/5_768.png'
import case6_768 from './organisms/SectionCases/imgs/6_768.png'
import case1_425 from './organisms/SectionCases/imgs/1_425.png'
import case2_425 from './organisms/SectionCases/imgs/2_425.png'
import case3_425 from './organisms/SectionCases/imgs/3_425.png'
import case4_425 from './organisms/SectionCases/imgs/4_425.png'
import case5_425 from './organisms/SectionCases/imgs/5_425.png'
import case6_425 from './organisms/SectionCases/imgs/6_425.png'
import case1_375 from './organisms/SectionCases/imgs/1_375.png'
import case2_375 from './organisms/SectionCases/imgs/2_375.png'
import case3_375 from './organisms/SectionCases/imgs/3_375.png'
import case4_375 from './organisms/SectionCases/imgs/4_375.png'
import case5_375 from './organisms/SectionCases/imgs/5_375.png'
import case6_375 from './organisms/SectionCases/imgs/6_375.png'
import case1_320 from './organisms/SectionCases/imgs/1_320.png'
import case2_320 from './organisms/SectionCases/imgs/2_320.png'
import case3_320 from './organisms/SectionCases/imgs/3_320.png'
import case4_320 from './organisms/SectionCases/imgs/4_320.png'
import case5_320 from './organisms/SectionCases/imgs/5_320.png'
import case6_320 from './organisms/SectionCases/imgs/6_320.png'
import phoneImg from './molecules/contactsBlock/imgs/phoneImg.svg'
import mailImg from './molecules/contactsBlock/imgs/mailImg.svg'

export const link = [
    { id: Math.random(), link: '/', text: 'ГЛАВНАЯ', offset: -150 },
    { id: Math.random(), link: '/all-cases', text: 'ПРОЕКТЫ', offset: -100 },
    { id: Math.random(), link: '/services', text: 'УСЛУГИ', offset: -100 },
    /* { id: Math.random(), link: 'solutions', text: 'РЕШЕНИЯ', offset: -100 }, */
    /* { id: Math.random(), link: '/partners', text: 'ПОЧЕМУ МЫ', offset: -150 }, */
    /* {id:Math.random(),link:'ourTeam',text:'ВАКАНСИИ',offset:-250}, */
    { id: Math.random(), link: '/contacts', text: 'КОНТАКТЫ', offset: -680 },
]
export const main = {
    MainTitle: "Заставьте лучшие <span class=header__title_color >WEB </span>И <span class=header__title_color>IT</span>-решения работать на вашу прибыль",
    MainSubtitle: "Вы – ставите задачу, DAIMAX.team – выводит ваш бизнес в цифровое пространство вместе с громким именем, запоминающимся фирменным стилем и крутым шустрым сайтом.",
    MainSubtitleTwo: 'Готовы к успеху на рынке товаров и услуг?'
}
export const services_page = {
    MainTitle: "Услуги <span class=header__title_color >WEB</span>-студии DAIMAX.team",
    MainSubtitle: "Объединяя опыт и видение будущего, технологии и дизайн, мы помогаем бизнесу и государству быть на шаг впереди в меняющемся цифровом мире",
}
export const contacts_page = {
    MainTitle: "ВАШЕМУ БИЗНЕСу Необходимо <span class=header__title_color >ОСОБОЕ РЕШЕНИЕ</span> ?",
}
export const cases_page = {
    MainTitle: "Пусть завершённые <span class=header__title_color >проекты</span> говорят за нас",
}

export const brief = [
    { title: 'Расскажите о компании', subtitle: 'Какова ваша область деятельности, продукты, услуги? Кто ваши конкуренты? В чем ваше отличие от них?' },
    { title: 'В чем ваша задача?', subtitle: 'Чего хотите достичь в ближайшем будущем? Что вам мешает?' },
    { title: 'Каким вы видите решение задачи?', subtitle: 'Как планируете достичь своих целей? Какие решения пробовали раньше?' },
    { title: 'Какие у вас ожидания от результата?', subtitle: 'Как вы видите решение задачи? На что это должно быть похоже?' },
    { title: 'Какие у вас сроки и бюджеты?', subtitle: 'Каков ваш бюджет? Сколько времени вы планируете потратить?' },
]
export const section_services = [
    { id: Math.random(), title: 'Создание сайтов', src: card1, name: 'development' },
    { id: Math.random(), title: 'Веб-дизайн', src: card2, name: 'web-design' },
    { id: Math.random(), title: 'Аудит сайта', src: card3, name: 'audit' },
    { id: Math.random(), title: 'SEO-продвижение', src: card4, name: 'SEO' },
    { id: Math.random(), title: 'Интеграция БИТРИКС 24', src: card5, name: 'bitrix'}
    /* { id: Math.random(), title: 'Контекстная реклама', src: card5, name: 'Contextual-advertising' },
    { id: Math.random(), title: 'Графический дизайн', src: card6, name: 'Graphic-design' } */
]
export const section_our_cases_text = [
    'С 2019 года мы собрали немало проектов – они наглядно показывают результат, который получили клиенты DAIMAX.team',
    'Посмотрите, какие сайты, порталы, интернет-магазины, CRM и ERP системы были запущены, какие баннеры и рассылки разработали наши дизайнеры и маркетологи. А после представьте ваш будущий проект, основанный на новаторских информационно-технологических решениях и нашем опыте.',
    'Будьте готовы к тому, чтобы получить от нас больше, чем указано в договоре.'
]
export const section_our_cases = [
    { id: 1, link: 'http://touch-table.ru/' },
    { id: 2,link: 'https://ovoschnoi-razval.ru/' },
    { id: 3, link: 'https://koacs.ru/' },
    { id: 4, link: 'https://pinoflowers.ru/', },
    { id: 5, link: 'http://vrst-service.ru/' },
    { id: 6, link: 'https://heart-2-heart.ru/' },
]
export const services_menu = {
    title: 'УСЛУГИ', subtitle: [
        'Вы вправе рассчитывать на полную адаптивность проекта к реалиям вашей сферы бизнеса. Никаких типовых решений и стандартных шаблонов мышления — только тщательная аналитика, выстраивание продукта и стратегии онлайн-продаж конкретно под вашу целевую аудиторию.',
        'Разносторонний опыт команды DAIMAX.team дает такую возможность.'
    ]
}

export const services_info = [
    { id: Math.random(), title: 'Аналитика', subtitle: 'Анализ бизнес-процессов с последующим предложением по автоматизации. Подготовка аналитических записок любой сложности. Разработка технических заданий под нашу реализацию и как отдельного продукта.' },
    { id: Math.random(), title: 'Проектирование интерфейсов', subtitle: 'Делаем так, чтобы вашим продуктом было удобно и приятно пользоваться. Применяем все современные дизайн-видения.' },
    { id: Math.random(), title: 'Back-end', subtitle: 'Реализуем серверную часть согласно требованиям проекта. Подскажем как подобрать серверное оборудование.' },
    { id: Math.random(), title: 'Консалтинг', subtitle: 'Изучаем рынок и отраслевые решения, бизнес-процессы, технологии заказчика и потребности пользователей. Выявляем зоны роста вашего продукта. Оцениваем предстоящий проект. Проводим приоритезацию бэклога. Помогаем принять решение на основе данных.' },
    { id: Math.random(), title: 'Мобильный фронтенд', subtitle: 'Анализ бизнес-процессов с последующим предложением по автоматизации. Подготовка аналитических записок любой сложности. Разработка технических заданий под нашу реализацию и как отдельного продукта.Применяем как нативные, так и кроссплатформенные технологические решения. Ваш продукт не потеряет в актуальности и поддержке.' },
    { id: Math.random(), title: 'Веб-фронтенд', subtitle: 'Создаём современный интерфейс с возможностью гибкой настройки и интеграцией сторонних сервисов. Улучшаем пользовательский опыт.' },
]
export const solutions_menu = {
    title: 'РЕШЕНИЯ', subtitle: [
        'Мы уверены, что разрабатываем технологичные и функциональные решения, которые способны любой бизнес сделать успешным. А все потому, что работаем по собственной методике, с реальным погружением в специфику отрасли и изучением всех возможностей привлечь к вам доход.',
        'Вот лишь малая часть реализованных отраслевых проектов.'
    ]
}

export const solutions_info = [
    { id: Math.random(), title: 'Личный кабинет', subtitle: 'Реализованы проекты для операторов сотовой связи и для операторов сетей АЗС. Личные кабинеты для физических и юридических лиц. Мобильный и веб фронтенд и автоматизированные системы, реализующие интеграцию с биллингом и платежными системами, а также интеграцию с информационными системами в рамках экосистемы оператора. В проектах операторов сетей АЗС интеграция с бонусной системой и базовым оборудованием автозаправочных станций.' },
    { id: Math.random(), title: 'Мобильный банк-клиент', subtitle: 'Проекты от региональных до федеральных банков ТОП 5: Газпромбанк, РНКБ, ВТБ, Банк Москвы, Крайинвестбанк. Проектирование и разработка фронтальных решений для управления финансами на мобильном устройстве. Решение задач заказчика по замене банк-клиента на масштабируемые решения с дополнительными интеграциями вне текущего архитектурного ландшафта банков.' },
    { id: Math.random(), title: 'Системы для проведения мероприятий', subtitle: 'Проекты от региональных до федеральных банков ТОП 5: Газпромбанк, РНКБ, ВТБ, Банк Москвы, Крайинвестбанк. Проектирование и разработка фронтальных решений для управления финансами на мобильном устройстве. Решение задач заказчика по замене банк-клиента на масштабируемые решения с дополнительными интеграциями вне текущего архитектурного ландшафта банков.' },
    { id: Math.random(), title: 'Транспортные решения', subtitle: 'Автономная автоматизированная система управления в области организации и реализации бизнес-процесса услуг такси. Бэк система, мобильное фронтальное решение для клиентов такси и отдельно клиент для водителей для обеспечения управления заявками. Транспортные решения для управления билетным составом на карте Тройка для московского общественного транспорта. Мобильный клиент с возможностью выбрать билет, купить его и записать на карту посредством технологии NFC.' },
]

export const options = [
    { id: Math.random(), title: "Дизайн", chapterOption: [{ title: "Шаблонный дизайн", price: 125, defaultActive: true, time: 1 }, { title: "Уникальный дизайн", price: 300, defaultActive: false, time: 2 }, { title: "Готовый дизайн", price: 400, defaultActive: false, time: 3 }] },
    { id: Math.random(), title: "Backend", chapterOption: [{ title: "DataBase", price: 400, defaultActive: true, time: 4 }, { title: "Уникальный Backend", price: 1000, defaultActive: false, time: 4 }, { title: "Много страниц", price: 2000, defaultActive: false, time: 4 }] }
]
export const not_found = {
    title: 'Страница временно в разработке',
    text: 'Наш сайт в разработке, но мы уже готовы к работе! Хотите узнать больше о компании, заказать сайт или просто поздороваться? Напишите нам и мы свяжемся с Вами как можно быстрее',
    email: 'daimaxteam@yandex.ru',
    telephone: '+7 (495) 431-81-31',
    telegram: '',
    whats: '',
    behance: ''
}

export const section_services_cases_card = [
    { id: 1, link: 'http://touch-table.ru/' },
    { id: 2, link: 'http://vrst-service.ru/' },
    { id: 3, link: 'https://heart-2-heart.ru/', },
    { id: 4, link: 'https://pinoflowers.ru/', },
    { id: 5, link: 'https://ovoschnoi-razval.ru/', },
    { id: 6, background: '#000', title: 'тут может быть ваш проект' },
    { id: 7, link: 'https://koacs.ru/' },
    { id: 8, /* link:'*', */background: '#000', title: 'тут может быть ваш проект' },
    { id: 9, /* link:'*', */background: '#000', title: 'тут может быть ваш проект' },
    { id: 10, /* link:'*', */background: '#000', title: 'тут может быть ваш проект' },
    { id: 11, /* link:'*', */background: '#000', title: 'тут может быть ваш проект' }
]
export const services = [
    {
        id: 1,
        name: 'development',
        MainTitle: "<span class=header__title_color >СОЗДАЕМ</span> ЛЕНДИНГИ ВНУШАЮЩИЕ ДОВЕРИЕ И <span class=header__title_color>САЙТЫ</span>, КОТОРЫЕ <span class=header__title_color>ПРОДАЮТ</span>",
        MainSubtitle: "Чтобы создать сайт, систему или приложение, которые будут успешно решать задачи бизнеса, необходимо спроектировать удобный пользовательский интерфейс. Мы не только разрабатываем интерфейсы под новую бизнес-идею, но и выполняем оценку существующих интерфейсов и улучшаем их. Помимо сайтов мы работаем над различными системами внутрикорпоративного пользования (CRM, ERP), мобильными приложениями, банкоматами и терминалами, голосовыми системами (IVR) и многим другим.",
        title: 'УСЛУГИ',
        subtitle: ["Разносторонний опыт нашей команды даёт возможность эффективно работать с разными сферами бизнеса. Мы не предлагаем типовых решений, не ограничены стандартными шаблонами мышления и готовы развиваться вместе с нашими продуктами. Всегда отталкиваемся от индивидуального запроса клиента и специфики отрасли."],
        price: "140 000",
        constants: [{
            title: "Лендинг",
            subtitle: "Разрабатываем структуру и дизайн страницы сайта, которая помогает увеличить количество продаж без существенных временных и материальных затрат.",
            deadline: "1"
        }, {
            title: "Корпоративный сайт",
            subtitle: "Структура сайта разрабатывается с учетом специфики деятельности компании и пожеланий заказчика. Созданный макет согласовывается с клиентом. После утверждения предложенного варианта структуры сайта наши сотрудники приступают к работе. Разработка корпоративного сайта является одной из самых сложных задач для программистов – реализация данные проектов требует большого объема технических ресурсов и временных затрат.",
            deadline: "6",
        }, {
            title: "Интернет-магазин",
            subtitle: "Пользователь принимает решение остаться на сайте или закрыть его за первые 10 секунд. Поэтому мы делаем его быстрым и понятным: настраиваем работу сервера, используем функционал кеширования, правильно готовим контент, придерживаемся линейной и простой структуры.",
            deadline: "9"
        }, {
            title: "Сайт-визитка",
            subtitle: "Чтобы рассказать о своем бизнесе в интересном, эффектном и лаконичном формате, необходимо грамотно представить его в интернете. Сделать это можно, создав сайт-визитку. Потенциальные клиенты и партнеры смогут узнать о вас самое главное, и при этом затратят минимум своего времени.",
            deadline: "2"
        },
        ],
        helmet:{
            title:'Заказать разработку и создание сайта в Москве',
            description:'DAIMAX.team - лучшие решения для вашего бизнеса. У нас вы можете заказать создание и разработку сайта под ключ в Москве! Создаем лендинги, внушающие доверие и сайты, которые продают!',
            keywords:'заказать разработку сайта, заказать разработку сайта в москве, заказать создание сайта, заказать создание сайтов москва, создание и разработка сайтов заказать'
        }
    }, {
        id: 2,
        name: 'web-design',
        MainTitle: "СОЗДАЕМ <span class=header__title_color>УНИКАЛЬНЫЕ</span> И ПРИВЛЕКАТЕЛЬНЫЕ <span class=header__title_color>ДИЗАЙНЫ</span> ДЛЯ БИЗНЕСА И ТОВАРОВ",
        title: 'УСЛУГИ',
        subtitle: ["Разносторонний опыт нашей команды даёт возможность эффективно работать с разными сферами бизнеса. Мы не предлагаем типовых решений, не ограничены стандартными шаблонами мышления и готовы развиваться вместе с нашими продуктами. Всегда отталкиваемся от индивидуального запроса клиента и специфики отрасли."],
        price: "165 000",
        constants: [{
            title: "Веб-дизайн сайта",
            subtitle: "Даже самый хороший товар или услуга требуют хорошей упаковки и правильной подачи. Мы поможем вам оформить ваше предложение в современный технологичный вид. Уникальный дизайн сайта для уникального предложения.",
            deadline: "3"
        }, {
            title: "Веб-дизайн сервисов и порталов",
            subtitle: "Структура сайта разрабатывается с учетом специфики деятельности компании и пожеланий заказчика. Созданный макет согласовывается с клиентом. После утверждения предложенного варианта структуры сайта наши сотрудники приступают к работе. Разработка корпоративного сайта является одной из самых сложных задач для программистов – реализация данные проектов требует большого объема технических ресурсов и временных затрат.",
            deadline: "6",
        }, {
            title: "Веб-дизайн интернет-магазина",
            subtitle: "Пользователь принимает решение остаться на сайте или закрыть его за первые 10 секунд. Поэтому мы делаем его быстрым и понятным: настраиваем работу сервера, используем функционал кеширования, правильно готовим контент, придерживаемся линейной и простой структуры.",
            deadline: "9"
        }, {
            title: "Сайт-визитка",
            subtitle: "Чтобы рассказать о своем бизнесе в интересном, эффектном и лаконичном формате, необходимо грамотно представить его в интернете. Сделать это можно, создав сайт-визитку. Потенциальные клиенты и партнеры смогут узнать о вас самое главное, и при этом затратят минимум своего времени.",
            deadline: "2"
        },
        ],
        helmet:{
            title:'Заказать разработку веб дизайна  сайта',
            description:'DAIMAX.team - лучшие решения для вашего бизнеса. Предлагаем заказать разработку уникального и привлекательного веб дизайн сайта для бизнеса и товаров!',
            keywords:'веб дизайн и разработка сайтов, заказать дизайн сайта, заказать разработку дизайна сайта, разработка дизайна сайта'
        }
    }, {
        id: 3,
        name: 'audit',
        MainTitle: "ИССЛЕДУЕМ ВАШ САЙТ, ОЦЕНИМ ЕГО СОСТОЯНИЕ И ПОВЫСИМ ПРИВЛЕКАТЕЛЬНОСТЬ ДЛЯ ПОЛЬЗОВАТЕЛЕЙ",
        MainSubtitle: "Профессиональный аудит необходим каждому ресурсу. Контент устаревает, меняются алгоритмы ранжирования, развиваются технологии. Это приводит к падению трафика и, как следствие, доходности сайта. Проведение комплексного аудита поможет обнаружить и устранить ошибки, увеличить продажи и конверсию вашего сайта.",
        title: 'УСЛУГИ',
        subtitle: ["Разносторонний опыт нашей команды даёт возможность эффективно работать с разными сферами бизнеса. Мы не предлагаем типовых решений, не ограничены стандартными шаблонами мышления и готовы развиваться вместе с нашими продуктами. Всегда отталкиваемся от индивидуального запроса клиента и специфики отрасли."],
        price: "80 000",
        constants: [{
            title: "Маркетинговый аудит",
            subtitle: "Отчет, включающий рекомендации по улучшению работоспособности сайта, дизайну и юзабилити, а также маркетинговую стратегию по продвижению. Мы подготовим четкий план, строго следуя которому вы улучшите техническое состояние сайта, повысите качество контента и за счет этого привлечете на свой сайт трафик и лиды.",
            deadline: ""
        }, {
            title: "Технический и юзабилити аудит",
            subtitle: "Аудит юзабилити выявит недостатки сайта, которые мешают вам зарабатывать больше. Устранив выявленные недостатки, вы сможете увеличить количество звонков и обращений при прежней посещаемости. Кроме того, исправление ошибок в юзабилити положительно отразится на поисковом продвижении: чем удобнее и понятнее сайт, тем лучше поведенческие факторы и выше позиции в поисковой выдаче.",
            deadline: "",
        }, {
            title: "Поисковый аудит",
            subtitle: "Оценка продвижения сайта поможет понять его реальное положение в поисковых системах, а также потенциальный рост и объем продаж с органического трафика. Мы проведем полный анализ сайта и подготовим для вас стратегию его дальнейшего развития и расширения его аудитории. Исправление выявленных технических ошибок на вашем сайте позволит повысить его ранжирование в поисковой выдаче.",
            deadline: ""
        },
        ],
        helmet:{
            title:'Заказать комплексный аудит сайта в Москве',
            description:'DAIMAX.team  предлагает заказать профессиональный комплексный аудит сайта в Москве - маркетинговый, технический, юзабилити, поисковый. Обнаружим и устраним ошибки, увеличим продажи и конверсию вашего сайта.',
            keywords:'заказать аудит сайта, заказать комплексный аудит сайта, комплексный аудит сайта, комплексный аудит сайта в москве'
        }
    }, {
        id: 4,
        name: 'SEO',
        MainTitle: "ПРОДВИГАЕМ ВАШ БИЗНЕС В ПЕРВЫЕ РЯДЫ",
        MainSubtitle: "Оценка продвижения сайта поможет понять его реальное положение в поисковых системах, а также потенциальный рост и объем продаж с органического трафика. Мы проведем полный анализ сайта и подготовим для вас стратегию его дальнейшего развития и расширения его аудитории. Исправление выявленных технических ошибок на вашем сайте позволит повысить его ранжирование в поисковой выдаче.",
        title: 'УСЛУГИ',
        subtitle: ["Разносторонний опыт нашей команды даёт возможность эффективно работать с разными сферами бизнеса. Мы не предлагаем типовых решений, не ограничены стандартными шаблонами мышления и готовы развиваться вместе с нашими продуктами. Всегда отталкиваемся от индивидуального запроса клиента и специфики отрасли."],
        price: "80 000",
        constants: [{
            title: "Поисковое продвижение",
            subtitle: "Сегодня как никогда актуально привести в рабочий режим все имеющиеся активы.Каждый элемент вашего бизнес-механизма должен работать как часы и приносить максимальную прибыль.Очевидно, что больше всего внимания поулчает тот, кто всегда на виду. Поэтому первые позиции поисковых систем сейчас просто на расхват.Всё просто - кто выше, того и клиенты.",
            deadline: ""
        }, {
            title: "SEO оптимизация сайтов",
            subtitle: "Перед выведением сайта в топ поисковых систем хорошо бы понять в каком он вообще состоянии.Для этого используется SEO оптимизация сайтов. Это комплексный аудит вашего ресурса и свод рекомендаций по улучшению видимости. К тому же без соблюдения правил легко спустить seo бюджет в трубу или даже попасть под фильтры поисковиков.",
            deadline: "",
        },
        ],
        helmet:{
            title:'Заказать Seo оптимизацию и продвижение сайтов в Москве',
            description:'DAIMAX.team предлагает заказать Seo оптимизацию и продвижение сайтов в Москве - выведем ваш бизнес в первые ряды, устраним ошибки, предоставим стратегию развития!',
            keywords:'seo оптимизация и продвижение сайтов москва, seo продвижение сайта в москве, seo продвижение сайта заказать, seo продвижение сайтов заказать в москве'
        }
    },
    {
        id: 5,
        name: 'bitrix',
        MainTitle: "ИНТЕГРАЦИЯ БИТРИКС24",
        MainSubtitle: "Интеграция корпоративного портала со сторонними программами или веб-ресурсами необходима для более эффективной работы компании. Подключение дополнительных модулей к Б24 позволяет снизить объем работы для сотрудников предприятия, увеличить конверсию и стимулировать продажи.",
        title: 'УСЛУГИ',
        subtitle: ["Разносторонний опыт нашей команды даёт возможность эффективно работать с разными сферами бизнеса. Мы не предлагаем типовых решений, не ограничены стандартными шаблонами мышления и готовы развиваться вместе с нашими продуктами. Всегда отталкиваемся от индивидуального запроса клиента и специфики отрасли."],
        price: "80 000",
        constants: [{
            title: "ТИПЫ ИНТЕГРАЦИЙ БИТРИКС24",
            subtitle: "Наша компания интегрирует Bitrix с продуктами 1С, почтовыми службами, сервисами аналитики или мессенджерами. Поможем подключить телефонию и интегрировать портал с вашим сайтом или интернет-магазином.",
            deadline: ""
        }, {
            title: "ИНТЕГРАЦИЯ БИТРИКС24 С САЙТОМ, ИНТЕРНЕТ МАГАЗИНОМ",
            subtitle: "Передача данных с сайта к корпоративному порталу позволяет:\n" +
                "\n" +
                "Выгружать информацию из заполненных форм CRM.\n" +
                "Передавать заказы для обработки в Б24.\n" +
                "Синхронизировать виджеты, чат-боты и формы обратной связи.\n" +
                "Провести синхронизацию CRM с сайтом.",
            deadline: "",
        },
        ],
        helmet:{
            title:'ИНТЕГРАЦИЯ БИТРИКС24',
            description:'Интеграция корпоративного портала со сторонними программами или веб-ресурсами необходима для более эффективной работы компании. Подключение дополнительных модулей к Б24 позволяет снизить объем работы для сотрудников предприятия, увеличить конверсию и стимулировать продажи.',
            keywords:''
        }
    },
]
export const cases = [
    {
        size: '1920', case: [
        { type: 'landing', src: case1,link:'http://touch-table.ru/' },
        { type: 'landing', src: case2,link:'http://vrst-service.ru/' },
        { type: 'magazine', src: case3,link:'https://heart-2-heart.ru/' },
        { type: 'magazine', src: case4,link:'https://koacs.ru/' },
        { type: 'magazine', src: case5,link:'https://pinoflowers.ru/' },
        { type: 'magazine', src: case6,link:'https://ovoschnoi-razval.ru/' }
    ]
    },
    {
        size: '1440', case: [
            { type: 'landing', src: case1,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6,link:'https://ovoschnoi-razval.ru/' }
        ]
    },
    {
        size: '1280', case: [
            { type: 'landing', src: case1_1280,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2_1280,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3_1280,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4_1280,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5_1280,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6_1280,link:'https://ovoschnoi-razval.ru/' }
        ]
    },
    {
        size: '1024', case: [
            { type: 'landing', src: case1_1024,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2_1024,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3_1024,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4_1024,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5_1024,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6_1024,link:'https://ovoschnoi-razval.ru/' }
        ]
    },
    {
        size: '768', case: [
            { type: 'landing', src: case1_768,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2_768,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3_768,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4_768,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5_768,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6_768,link:'https://ovoschnoi-razval.ru/' }
        ]
    },
    {
        size: '425', case: [
            { type: 'landing', src: case1_425,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2_425,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3_425,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4_425,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5_425,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6_425,link:'https://ovoschnoi-razval.ru/' }
        ]
    },
    {
        size: '375', case: [
            { type: 'landing', src: case1_375,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2_375,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3_375,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4_375,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5_375,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6_375,link:'https://ovoschnoi-razval.ru/' }
        ]
    },
    {
        size: '320', case: [
            { type: 'landing', src: case1_320,link:'http://touch-table.ru/' },
            { type: 'landing', src: case2_320,link:'http://vrst-service.ru/' },
            { type: 'magazine', src: case3_320,link:'https://heart-2-heart.ru/' },
            { type: 'magazine', src: case4_320,link:'https://koacs.ru/' },
            { type: 'magazine', src: case5_320,link:'https://pinoflowers.ru/' },
            { type: 'magazine', src: case6_320,link:'https://ovoschnoi-razval.ru/' }
        ]
    }
]

export const contactsBlock =[{
    link: "tel:+74954318131",
    img: phoneImg,
    contactsContent: "+7 (495)431-81-31"
},{
    link: "mailTo:daimaxteam@yandex.ru",
    img: mailImg,
    contactsContent: "daimaxteam@yandex.ru"
},
]

export const sliderArray = [
    {
        id: 1,
        img: "",
        header: "Сбор информации",
        upperText: "До начала создания проекта мы стараемся получить от клиента максимум информации о пожеланиях по дизайну, запланированной монетизации ресурса, и в целом о том, каким он видит будущий собственный сайт.",
        lowerText: "На этапе сбора информации определяется цена за создание сайта, количество страниц и вкладок, степень насыщенности контентом и иными элементами.",
    },{
        id: 2,
        img: "",
        header: "Изучение работ конкурентов",
        upperText: "Чтобы создать для клиента по-настоящему мощный сайт, с которым он сможет уверенно покорять интернет-рынок, необходим тщательный анализ аналогичных продуктов конкурентов, определение их сильных и слабых сторон. Исходя из полученной информации, в создаваемый проект внедряются необходимые данные и опции.",
        lowerText: "В ходе выполняемых во время этого этапа действий происходит согласование вариантов УТП, детали сайта продумываются до мелочей. Всё это делается для того, чтобы после его запуска заказчик мог занять лидирующие позиции на рынке и получить ощутимые преимущества над ресурсами конкурентов.",
    },{
        id: 3,
        img: "",
        header: "Прототипирование сайта",
        upperText: "На основании полученной от клиента информации мы можем составить полное представление о том, как должен воплощаться будущий проект и каким он будет. Теперь можно приступать к созданию прототипа. Это своего рода техническое задание, каркас, на котором будет основываться будущий дизайн и верстка. Для выполнения данной задачи задействуется команда из менеджера, ответственного за проект, интернет-маркетолога и копирайтера.",
        lowerText: "Данный этап включает в себя составление структуры сайта, с формированием и распределением нужной информации, проработкой форм захвата, указанием последовательностей и позиций для различных отделов сайта (главная страница, меню, каталог и др.).",
    },{
        id: 4,
        img: "",
        header: "Отрисовка дизайна",
        upperText: "Для большинства наших заказчиков создание дизайна сайта - самый интересный момент. Ведь на данном этапе уже можно практически наглядно видеть, как сайт в итоге будет выглядеть. На основании отражённых в прототипе данных дизайнер прорисовывает каждую страницу и формирует общую визуальную картину сайта.",
        lowerText: "Но это ещё не готовый сайт, а только его эскиз. На данном этапе заказчик может вносить правки, если его не устраивают какие-то моменты. После согласования спорных моментов готовая картинка сайта отправляется на верстку.",
    },{
        id: 5,
        img: "",
        header: "Вёрстка и программирование создаваемого сайта.",
        upperText: "Данный этап - ключевой момент в создании сайта. Ведь именно сейчас детализированная, прорисованная \"картинка\" становится полноценным, функциональным ресурсом. Верстка сайта даёт возможность открытия сайта в браузере, проверки корректности открытия форм захвата, адаптации дизайна сайта под открытие на различных экранах и др.",
        lowerText: "Осуществляется установка сайта на домен, и производится его публикация на хостинге. Сайт готов.",
    },{
        id: 6,
        img: "",
        header: "Наполнение созданного ресурса контентом",
        upperText: "Основное решение сайта готово, он работоспособен по всем параметрам и опубликован. Но для того, чтобы сайт приносил доход, необходимо наполнение его соответствующим контентом, решающим задачи заказчика. В момент передачи сайта владельцу наши специалисты подробно разъяснят нюансы пользования, методы создания карточек товара, то, как при необходимости сайт можно будет впоследствии дополнить текстовой, графической и видеоинформацией. Заказчик может заполнять сайт сам.",
        lowerText: "Если по каким-либо причинам он не может этого сделать, мы с удовольствием возьмём это на себя - профессионально наполним сайт информативным контентом и подготовим его к приёму трафика.Гарантированно лучшая разработка сайтов в г.Москве. Вы получите готовое, красивое, удобное, современное решение ваших задач.",
    },{
        id: 7,
        img: "",
        header: "Осуществление технической поддержки",
        upperText: "Данный этап по факту - не разовый пункт, а постоянное, продолжительное взаимодействие. В абсолютном большинстве сайты используются в бизнесе как эффективный инструмент привлечения клиентов. Но со временем возникает необходимость в изменении ценовой политики, ассортимента каталога, информации об акциях и бонусах, новостях и др.",
        lowerText: "Вы можете вносить необходимую информацию собственноручно, или же доверить этот вопрос нам. Мы с удовольствием поддержим ваш сайт, и не только. Вы всегда можете обратиться к нашим специалистам по различным вопросам, в том числе и связанным с функциональностью и изменением элементов сайта. Наша компания, основным направлением деятельности которой является профессиональная разработка сайтов под ключв г.Москва, всегда стоит на страже интересов клиентов, создавая предпосылки для создания крепкого, долгосрочного сотрудничества.",
    }
]